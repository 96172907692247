<template>
  <div class="h-screen w-full flex flex-col md:flex-row relative">
    <div
      class="hidden md:block md:w-1/2 xl:w-2/3 h-full relative"
      v-show="!isMobile"
    >
      <img :src="imgcur" class="h-full w-full object-cover" alt="" />
      <div class="absolute right-10 bottom-6 gap-2 flex">
        <img
          @click="imgcur = s"
          v-for="(s, si) in imglst"
          :key="si"
          :src="s"
          class="w-12 h-10 rounded-lg border-white border cursor-pointer"
        />
      </div>
    </div>
    <div
      class="flex gap-4 items-center absolute w-full md:w-1/2 xl:w-2/3 px-4 py-2 bg-white border-bottom"
    >
      <img src="/logo-last.png" class="w-28 h-9" />
    </div>
    <div class="w-full h-full overflow-auto md:w-1/2 xl:w-1/3 relative">
      <div
        class="hidden absolute top-0 left-0 justify-end w-full lg:flex bg-white border-b border-gray-200 text-sm"
        style="padding: 1rem"
      >
        Утас : 7000-2413
      </div>
      <div
        class="h-full w-full pt-[10rem] lg:pt-[14rem] flex items-center justify-center"
      >
        <div class="p-3 md:p-8">
          <div
            class="block md:hidden w-full h-full max-h-lg relative mb-10"
            v-show="!isMobile"
          >
            <img :src="imgcur" class="h-full w-full object-cover" alt="" />
            <div class="absolute right-2 bottom-2 gap-2 flex">
              <img
                @click="imgcur = s"
                v-for="(s, si) in imglst"
                :key="si"
                :src="s"
                class="w-10 h-8 rounded-lg border-white border cursor-pointer"
              />
            </div>
          </div>

          <form @submit.prevent="doSrch">
            <div class="font-semibold mb-2 text-left">
              Хот хоорондын авто зам ашигласны төлбөр шалгах
            </div>
            <div
              class="mb-4 lg:mb-2 text-sm leading-4 text-gray-500 text-justify"
            >
              Та дараах цонхонд өөрийн эзэмшдэг тээврийн хэрэгслийн улсын
              дугаарыг оруулан товчоодоор нэвтэрсэн мэдээллээ харах цахимаар
              төлөх боломжтой.
            </div>
            <div class="flex gap-2 mb-2 md:mb-4">
              <input
                v-model="p1"
                placeholder="...."
                maxlength="4"
                type="tel"
                ref="input1"
                @input="checkInputLength($event, 'input1')"
                :class="`block p-3 w-3/6 text-sm text-gray-900  rounded-lg border-2  ${
                  p1 != null && p1.length == 4
                    ? 'border-primary-400 bg-gray-50'
                    : 'bg-gray-100 border-cyan-300'
                }  shadow-sm focus:ring-gray-500 focus:border-gray-500`"
              />
              <!-- <input v-model="p2" placeholder="AA" @focus="isFocused1= true" ref="input2" @input="checkInputLength($event, 'input2')"
                                class="block p-3 w-2/6 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 " /> -->
              <a
                ref="input2"
                :class="`block p-3 w-2/6 text-sm ${
                  p2 == null || p2 == '' ? 'text-gray-100' : 'text-gray-900'
                } bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500`"
                @click="isFocused1 = true"
                >{{ p2 == null || p2 == "" ? "AA" : p2 }}</a
              >
              <!-- <input v-model="p3" placeholder="A" @focus="isFocused2= true" disabled
                                class="block p-3 w-1/6 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 " /> -->
              <a
                class="block p-3 w-2/6 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                @click="isFocused2 = true"
                >{{ p3 == null || p3 == "" ? "A" : p3 }}</a
              >
            </div>
            <button
              type="submit"
              class="py-2 md:py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg bg-blue-700 sm:w-fit hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600"
            >
              Шалгах
            </button>
          </form>
          <div class="flex gap-2 md:gap-4 mt-10 md:mt-10">
            <div
              style="padding-bottom: 3.5rem !important"
              class="rounded-xl border-2 border-gray-300 hover:border-gray-200 bg-gray-200 p-2 pb-14 relative"
            >
              <div class="">
                The international transports transitting/passing through the
                territory of Mongolia
              </div>
              <div class="absolute bottom-0 left-0 mt-6 w-full p-2">
                <button
                  @click="showPdfModal()"
                  class="w-full border border-gray-50 rounded-xl bg-white p-2 hover:bg-blue-500"
                >
                  Submit request
                </button>
              </div>
            </div>
            <div
              class="rounded-xl border-2 border-gray-300 hover:border-gray-200 bg-gray-200 p-2 relative"
            >
              <div class="pb-16 md:pb-12">
                Зөвшөөрөгдөх дээд хэмжээнээс хэтэрсэн задрах боломжгүй ачаа
                тээвэрлэх.
              </div>
              <div class="absolute bottom-0 left-0 mt-6 w-full p-2">
                <button
                  @click="reqObj.typeObj.modal = true"
                  class="w-full border border-gray-50 rounded-xl bg-white p-2 hover:bg-blue-500"
                >
                  Хүсэлт илгээх
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="padding: 1rem"
        class="hidden absolute bottom-0 left-0 justify-center text-md w-full lg:flex bg-white dark:bg-gray-800 z-20 border-t border-gray-200 dark:border-gray-700"
      >
        Хөгжүүлсэн
        <a
          href="https://gosmart.mn"
          style="display: inline-flex"
          target="_blank"
          ><img
            src="https://res.gosmart.mn//media/favicon/fav.png"
            style="height: 14px; margin-top: 2px"
          />
          ГӨҮСМАРТ ТМ ХХК</a
        >
      </div>
    </div>
  </div>

  <div
    v-if="isFocused1"
    class="overflow-y-auto overflow-x-hidden fixed bottom-0 right-0 left-0 z-50 justify-center items-end md:items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex"
  >
    <div class="relative p-4 w-full max-w-lg max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
        >
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Сонгоно уу
          </h3>
          <button
            @click="isFocused1 = false"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="default-modal"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 space-y-4">
          <div class="flex flex-wrap">
            <div
              @click="
                p2 = l;
                isFocused1 = false;
                isFocused2 = p2 == 'ДК' ? false : true;
              "
              v-for="(l, i) in letrs1"
              :key="`lt_${i}`"
              class="cursor-pointer p-1 w-1/5"
            >
              <div
                class="rounded-xl bg-gray-200 py-2 hover:bg-gray-400 text-center"
              >
                {{ l }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isFocused2"
    class="overflow-y-auto overflow-x-hidden fixed bottom-0 right-0 left-0 z-50 justify-center items-end md:items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex"
  >
    <div class="relative p-4 w-full max-w-lg max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
        >
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
            Сонгоно уу
          </h3>
          <button
            @click="isFocused2 = false"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="default-modal"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 space-y-4">
          <div class="flex flex-wrap">
            <div
              @click="
                p3 = l;
                isFocused2 = false;
              "
              v-for="(l, i) in letrs2"
              :key="`ii_${i}`"
              class="cursor-pointer p-1 w-1/5"
            >
              <div
                class="rounded-xl bg-gray-200 py-2 hover:bg-gray-400 text-center"
              >
                {{ l }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isFocused1 || isFocused2"
    @click="isFocused1 = false"
    class="fixed inset-0 z-40"
    style="background: rgba(0, 0, 0, 0.5)"
  ></div>

  <el-dialog
    v-model="reqObj.typeObj.modal"
    title="Хүсэлт илгээх төрлөө сонгоно уу"
    :fullscreen="isMobile"
    modal
    :lock-scroll="true"
    top="2vh"
    :append-to-body="true"
    width="30%"
    center
  >
    <div class="col-sm-12">
      <div style="margin-top: 1rem">
        <el-form>
          <el-form-item>
            <!-- <el-radio
              v-model="reqObj.type"
              @click="reqObj.typeSelected = true"
              :label="0"
              >Хүсэлт илгээх</el-radio
            >
            <el-radio
              v-model="reqObj.type"
              @click="reqObj.typeSelected = true"
              :label="1"
              >Зөрүү төлөх</el-radio
            > -->
            <el-alert
              :icon="Check"
              title="Зөвшөөрөл авах"
              :closable="false"
              type="success"
              description="Та шинээр зөвшөөрөл авах бол доор дарна уу"
              show-icon
              class="mb-2"
            />
            <el-button
              style="width: 100%"
              width="100%"
              type="success"
              @click="
                reqObj.typeObj.selectedType = 0;
                checkRequestId();
              "
              >Зөвшөөрөл авах</el-button
            >

            <!-- <el-radio-group v-model="reqObj.typeObj.selectedType" size="large">
              <el-radio-button label="Зөвшөөрөл авах" :value="0" />
              <el-radio-button label="Зөрүү төлбөр төлөх" :value="1" />
            </el-radio-group> -->
          </el-form-item>
          <el-form-item>
            <el-alert
              :icon="Check"
              title="Зөрүү төлбөр төлөх"
              :closable="false"
              type="info"
              description="Та зөрүү төлбөр төлөх бол доор дарна уу"
              show-icon
              class="mb-2"
            />
            <el-button
              style="width: 100%"
              width="100%"
              @click="reqObj.typeObj.selectedType = 1"
              >Зөрүү төлбөр төлөх</el-button
            >
          </el-form-item>

          <el-form-item
            v-show="reqObj.typeObj.selectedType == 1"
            label="Зөвшөөрлийн №"
          >
            <el-input-number
              :controls="false"
              v-model="reqObj.typeObj.requestId"
              style="width: 200px"
            ></el-input-number>
            <span
              class="text-muted mt-2"
              style="font-size: 12px; line-height: 16px"
              >Та өмнөх зөвшөөрөл дээр хэвлэгдсэн зөвшөөрлийн дугаарыг оруулна
              уу.</span
            >
          </el-form-item>
          <!-- <el-form-item
            v-show="reqObj.typeObj.selectedType == 1"
            
          >
           <el-button @click="checkRequestId()">Хайх</el-button>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer" v-show="reqObj.typeObj.selectedType == 1">
        <el-button @click="checkRequestId()">Хайх</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog
    title="Зөвшөөрөгдөх дээд хэмжээнээс хэтэрсэн задрах боломжгүй ачаа тээвэрлэх хүсэлт илгээх"
    v-model="reqObj.modal"
    :fullscreen="isMobile"
    modal
    :lock-scroll="true"
    top="2vh"
    :append-to-body="true"
    width="80%"
    @close="
      reqObj.typeSelected = false;
      reqObj.type = null;
      reqObj.step = 1;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <el-steps
            style="margin-top: 1rem"
            :active="reqObj.step"
            align-center
            v-show="!isMobile"
          >
            <el-step title="Ерөнхий мэдээлэл" description=""></el-step>
            <el-step title="Тээврийн хэрэгсэл бүртгэх" description=""></el-step>
            <el-step title="Төлбөр төлөх" description=""></el-step>
            <el-step title="Баримт хэвлэх" description=""></el-step>
          </el-steps>
          <div v-show="reqObj.step == 1" class="mt-4">
            <el-form
              @submit.prevent="onSubmit"
              ref="myform"
              :model="taskModel"
              :rules="reqObj.stepRequestRule"
              label-width="320px"
              class="myfrm"
              :label-position="isMobile ? 'top' : 'right'"
              width="100%"
            >
              <el-form-item
                label="Тээвэрлэлт хийх Иргэн, аж ахуйн нэгж байгууллагын нэр"
                required
                prop="task"
              >
                <el-input
                  v-model="taskModel.task"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item label="И-Баримт авах хэлбэр" required prop="i3">
                <el-radio v-model="taskModel.i3" :label="0">Иргэн</el-radio>
                <el-radio v-model="taskModel.i3" :label="1">ААН</el-radio>
              </el-form-item>
              <el-form-item
                v-show="taskModel.i3 == 1"
                label="И-Баримт авах ААН регистр"
                prop="st2"
              >
                <el-input
                  v-model="taskModel.st2"
                  style="width: 200px"
                ></el-input>
              </el-form-item>

              <el-form-item label="Тээвэрлэлт эхлэх огноо" required prop="d1">
                <el-date-picker
                  v-model="taskModel.d1"
                  type="date"
                  placeholder="Тээвэрлэлт эхлэх огноо сонгоно уу"
                  style="width: 200px"
                  :format="'YYYY-MM-DD'"
                  :value-format="'YYYY-MM-DD'"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="Тээвэрлэлт дуусах огноо" required prop="d2">
                <el-date-picker
                  v-model="taskModel.d2"
                  type="date"
                  placeholder="Тээвэрлэлт эхлэх огноо сонгоно уу"
                  style="width: 200px"
                  :format="'YYYY-MM-DD'"
                  :value-format="'YYYY-MM-DD'"
                ></el-date-picker>
              </el-form-item>

              <!-- <el-form-item label="Эхлэх цэг" required prop="locTypeId">
                <el-select
                  v-model="taskModel.locTypeId"
                  placeholder="Эхлэх цэг сонгоно уу"
                  style="width: 200px"
                >
                  <el-option
                    v-for="item in reqObj.places"
                    :key="item.id"
                    :label="item.s1"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item> -->

              <el-form-item label="Эхлэх цэг" required prop="locTypeId">
                <!-- {{reqObj.places}} -->
                <!-- v-model="taskModel.locTypeId" -->
                <!-- {{ taskModel.locTypeId }} {{ locTypeIdSelected }} -->
                <el-cascader
                  placeholder="Эхлэх цэг сонгоно уу"
                  style="width: 200px"
                  filterable
                  :options="reqObj.places"
                  clearable
                  v-model="locTypeIdSelected"
                  @change="cascaderOnChange('locTypeId', $event)"
                >
                </el-cascader>
              </el-form-item>
              <!-- v-model="taskModel.locTypeId1" -->
              <!-- {{ taskModel.locTypeId1 }} {{ locTypeId1Selected }} -->
              <el-form-item label="Хүрэх цэг" required prop="locTypeId1">
                <el-cascader
                  :options="reqObj.places"
                  filterable
                  clearable
                  placeholder="Хүрэх цэг сонгоно уу"
                  style="width: 200px"
                  v-model="locTypeId1Selected"
                  @change="cascaderOnChange('locTypeId1', $event)"
                >
                </el-cascader>
              </el-form-item>

              <el-form-item label="Нийт зай (км)" required prop="fl1">
                <el-input-number
                  :min="1"
                  v-model="taskModel.fl1"
                  style="width: 200px"
                ></el-input-number>
              </el-form-item>

              <el-form-item
                label="Албан хүсэлт (зураг, PDF)"
                style="margin-bottom: 5px"
                required
                prop="defaultAttachmentId"
              >
                <upldr
                  :id="taskModel.defaultAttachmentId"
                  @on-success="fileUploaded($event, 10)"
                ></upldr>
              </el-form-item>

              <el-form-item
                class="mg-t-15"
                label="Тээврийн цагдаагийн газрын зөвлөмж"
                style="margin-bottom: 5px"
                prop="secondAttachmentId"
              >
                <upldr
                  :id="taskModel.secondAttachmentId"
                  @on-success="fileUploaded($event, 11)"
                ></upldr>
              </el-form-item>

              <!-- <div
              class="alert alert-warning pd-5 mg-t-15"
              style="display: block"
            >
              <ul class="mg-b-0-f text-left mg-l-0">
                <li>Тээвэрлэх ачаа задрах боломжгүй</li>
              </ul>
            </div> -->

              <!-- <el-form-item label="Утасны дугаар" required prop="phone">
                <el-input
                  v-model="taskModel.phone"
                  style="width: 200px"
                ></el-input>
              </el-form-item> -->
              <el-form-item
                label="И-мэйл хаяг"
                required
                prop="mobile"
                class="mg-t-15"
              >
                <el-input
                  v-model="taskModel.mobile"
                  style="width: 200px"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="Санамжыг уншиж танилцсан"
                required
                prop="isTender"
              >
                <el-checkbox
                  required
                  v-model="taskModel.isTender"
                  @click="showConfirm()"
                  >Үйлчилгээний нөхцөл зөвшөөрөх</el-checkbox
                >
                <a role="button"></a>
                <!-- <el-input
                v-model="taskModel.isTender"
                style="width: 200px"
              ></el-input> -->
              </el-form-item>
              <!-- <el-form-item>
              
              
            </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer wd-100p text-right">
              <el-button
                @click="
                  resetForm();
                  reqObj.modal = false;
                "
                type="warning"
                >Болих</el-button
              >
              <el-button @click="onSubmit" type="primary">Илгээх</el-button>
            </div>
          </div>

          <div v-show="reqObj.step == 2">
            <el-form
              @submit.prevent="onSubmit"
              ref="vehicleform"
              :model="vehicleModel"
              :rules="reqObj.stepVehicleRule"
              :label-position="isMobile ? 'top' : 'right'"
              label-width="320px"
              class="myfrm"
              width="100%"
            >
              <!-- {{reqObj.vehicles}} -->
              <el-divider content-position="left"
                >Бүртгэсэн тээврийн хэрэгслүүд</el-divider
              >
              <el-table
                :data="reqObj.vehicles"
                style="width: 100%"
                empty-text="Тээврийн хэрэгсэл бүртгээгүй байна. "
              >
                <el-table-column prop="task" label="Улсын дугаар" width="120">
                </el-table-column>
                <el-table-column
                  prop="taskEn"
                  label="Чиргүүлийн дугаар"
                  width="120"
                >
                </el-table-column>
                <el-table-column prop="i1" label="Тэнхлэгийн тоо" width="110">
                </el-table-column>
                <el-table-column prop="fl1" label="Бодит жин" width="60">
                </el-table-column>
                <el-table-column prop="position" label="Жолоочын овог нэр">
                </el-table-column>
                <el-table-column prop="langCode" label="Жолоочын регистр">
                </el-table-column>
              </el-table>
              <div class="wd-100p text-center" style="margin-top: 1rem">
                <el-button
                  v-show="reqObj.vehicleFormShow == false"
                  @click="reqObj.vehicleFormShow = true"
                  type="success"
                  round
                  ><i class="el-icon-edit"></i> Тээврийн хэрэгсэл
                  нэмэх</el-button
                >
                <el-button
                  v-show="reqObj.vehicleFormShow == true"
                  @click="onVehicleSubmit"
                  round
                  type="primary"
                  >Бүртгэх</el-button
                >
              </div>
              <div v-show="reqObj.vehicleFormShow">
                <el-divider content-position="left"
                  >Тээврийн хэрэгсэл нэмэх</el-divider
                >
                <el-form-item
                  label="Тээврийн хэрэгслийн улсын дугаар"
                  required
                  prop="task"
                >
                  <el-input
                    @blur="checkRoadpayPayment"
                    v-model="vehicleModel.task"
                    style="width: 200px"
                  ></el-input>

                  <div v-show="vehicleModel.isTender" class="flex wd-100p">
                    <el-alert
                      class="mt-2 mb-2"
                      :closable="false"
                      :title="`Олон улс улсын чанартай авто зам ашигласны ${vehicleModel.dec1}₮ төлбөрийн үлдэгдэлтэй байна`"
                      type="error"
                      :description="`Төлбөр төлсөн тохиолдолд дараагийн алхам руу шилжинэ.`"
                      show-icon
                    >
                    </el-alert>
                  </div>
                  <!-- <div
            
            class="p-2 pb-0 mb-2 mt-2 text-blue-800 border border-blue-300 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:border-blue-800"
            
          >
            <div class=" items-center">
              
              <h3 class="text-lg font-medium">Төлбөр төлөхдөө анхаарах нь:</h3>
            </div>
            <div class="mt-1 mb-1 text-sm">
              Амжилттай төлсөн бол дахин төлөлт хийлгүй түр хүлээнэ үү.
            </div>
          </div> -->
                  <div v-show="vehicleModel.isTender" class="flex wd-100p">
                    <a
                      type="primary"
                      target="_blank"
                      class="el-button el-button--primary"
                      :href="`https://roadpay.mn/#/reg/${vehicleModel.task}`"
                      @click="checkRoadpayPayment"
                      >Авто зам ашигласны төлбөр төлөх</a
                    >
                    <el-button type="success" @click="checkRoadpayPayment"
                      >Төлбөр шалгах</el-button
                    >
                  </div>

                  <!-- <a
                      style="color: red; font-weight: 700"
                      :href="`https://roadpay.mn/#/reg/${vehicleModel.task}`"
                      target="_blank"
                    >
                      Та Хот хоорондын авто зам ашигласан төлбөрийн үлдэгдэлтэй
                      байна. Энд дарж төлбөрөө төлнө үү.
                    </a>
                    <el-button type="primary" @click="checkRoadpayPayment"
                      >Төлбөр төлсөн бол энд дарж ахин шалгана уу.</el-button
                    > -->
                </el-form-item>

                <el-form-item label="Ачааны нэр төрөл" required prop="st1">
                  <!-- <el-input
                    v-model="vehicleModel.st1"
                    style="width: 200px"
                  ></el-input>
                   -->
                  <el-select @change="axleCountChanged"
                    v-model="vehicleModel.st1"
                    placeholder="Ачааны нэр төрөл"
                  >
                    <el-option
                      v-for="item in achaaOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Чиргүүлтэй эсэх" prop="isRepeat">
                  <el-checkbox
                    @change="axleCountChanged"
                    required
                    v-model="vehicleModel.isRepeat"
                    >Чиргүүлтэй бол чагтлана уу.</el-checkbox
                  >
                </el-form-item>

                <div v-show="vehicleModel.isRepeat">
                  <el-form-item label="Чиргүүлийн дугаар" prop="taskEn">
                    <el-input
                      @blur="getChirguulType"
                      v-model="vehicleModel.taskEn"
                      style="width: 200px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Чиргүүлийн төрөл" prop="locTypeId">
                    <el-select
                      style="width: 200px"
                      v-model="vehicleModel.locTypeId"
                      placeholder="Чиргүүлийн төрөл сонгоно уу"
                      :disabled="true"
                    >
                      <el-option
                        v-for="item in reqObj.chirguulTypes"
                        :key="item.id"
                        :label="item.s1"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-form-item label="Нийт тэнхлэгийн тоо" prop="i1" required>
                  <el-input-number
                    @change="axleCountChanged"
                    :min="1"
                    :max="50"
                    v-model="vehicleModel.i1"
                    style="width: 200px"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="Бодит жин" prop="fl1" required>
                  <el-input-number
                    :min="bjMin"
                    :max="bjMax"
                    v-model="vehicleModel.fl1"
                    style="width: 200px"
                  ></el-input-number>
                  <el-alert
                    class="mt-2"
                    style="font-size: 10px !important"
                    :closable="false"
                    :title="`Хэрвээ тэнхлэгийн ачаалал хэтэрсэн тохиолдолд зөвшөөрөл
                      олгогдохгүй болохыг анхаарна уу!!!`"
                    type="warning"
                    :description="`MNS4598:2020 стандартын 5.8.2-т заасны дагуу 1 хөтлөгч
                    тэнхлэг дээр 11.5тн, хөтлөгдөх тэнхлэг дээр хамгийн их
                    ачаалал 10тн-оос ихгүй байна. ${bjMin} - ${bjMax}`"
                    show-icon
                  >
                  </el-alert>
                  <!-- <div
                    class="text-muted text-sm pd-t-10"
                    style="font-size: 12px"
                  >
                    MNS4598:2020 стандартын 5.8.2-т заасны дагуу 1 хөтлөгч
                    тэнхлэг дээр 11.5тн, хөтлөгдөх тэнхлэг дээр хамгийн их
                    ачаалал 10тн-оос ихгүй байна. {{ bjMin }} - {{ bjMax }}
                    <b style="color: red"
                      ><br />
                      Хэрвээ тэнхлэгийн ачаалал хэтэрсэн тохиолдолд зөвшөөрөл
                      олгогдохгүй болохыг анхаарна уу!!!</b
                    >
                  </div> -->
                </el-form-item>
                <el-form-item
                  label="Жолоочийн овог нэр"
                  required
                  prop="position"
                >
                  <el-input
                    v-model="vehicleModel.position"
                    style="width: 200px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Жолоочийн регистр"
                  required
                  prop="langCode"
                >
                  <el-input
                    v-model="vehicleModel.langCode"
                    style="width: 200px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Тээврийн хэрэгсэл, чиргүүл гэрчилгээний хуулбар"
                  required
                  prop="defaultAttachmentId"
                >
                  <upldr
                    :id="vehicleModel.defaultAttachmentId"
                    @on-success="fileUploaded($event, 20)"
                  ></upldr>
                </el-form-item>
                <el-form-item
                  label="Жолооны үнэмлэхний хуулбар"
                  required
                  prop="secondAttachmentId"
                >
                  <upldr
                    :id="vehicleModel.secondAttachmentId"
                    @on-success="fileUploaded($event, 21)"
                  ></upldr>
                </el-form-item>
                <el-form-item
                  label="Тээврийн хэрэгслийн зураг"
                  style="margin-bottom: 5px"
                  required
                  prop="thirdAttachmentId"
                >
                  <upldr
                    :id="vehicleModel.thirdAttachmentId"
                    @on-success="fileUploaded($event, 22)"
                  ></upldr>
                </el-form-item>
              </div>
              <!-- <div
                class="alert alert-warning pd-5 mg-t-15"
                style="display: block"
              >
                <ul class="mg-b-0-f text-left mg-l-0">
                  <li>Тээврийн хэрэгсэл чиргүүл</li>
                </ul>
              </div> -->
            </el-form>
            <div
              slot="footer"
              class="dialog-footer wd-100p text-right"
              v-show="reqObj.vehicleFormShow"
            >
              <!-- <el-button
                @click="
                  resetVehicleForm();
                "
                type="warning"
                >цэвэрлэх</el-button
              > -->
              <el-button @click="onVehicleSubmit" type="primary"
                >Бүртгэх</el-button
              >
              <el-button @click="getInvoice" type="warning"
                >Төлбөр төлөх</el-button
              >
            </div>
          </div>
          <div v-show="reqObj.step == 3">
            <!-- {{ reqObj.invoiceModel }} -->
            <div
              v-for="item in reqObj.paymentStep.paymentOptions"
              :key="`po_${item.id}`"
              @click="paymentMethodSelected(item)"
            >
              <!-- {{ item.name }} -->
            </div>
            <!-- {{ reqObj.paymentStep.paymentMethodSelected }} -->
            <div
              v-if="
                reqObj.paymentStep.paymentMethodSelected != null &&
                reqObj.paymentStep.paymentMethodSelected.id == 1
              "
            >
              <!-- {{ reqObj.paymentStep.invoiceQpay }} -->
              <!-- QPAY -->
              <!-- {{isMobile}} -->
              <div
                v-if="!isMobile"
                class="wd-100p text-center"
                style="justify-content: center; margin-top: 2rem"
              >
                <h3>Үнийн дүн нийт төлөх</h3>

                <div>
                  <h2>
                    {{ reqObj.paymentStep.invoiceQpay.budget.toLocaleString() }}
                    ₮
                  </h2>
                </div>
                <div
                  class="wd-100p text-center d-flex"
                  style="justify-content: center"
                >
                  <img
                    :src="`data:image/jpeg;base64,${reqObj.paymentStep.invoiceQpay.json_data.qPay_QRimage}`"
                  />
                </div>
              </div>

              <div v-show="isMobile">
                <div class="wd-100p text-center">
                  <br />
                  <h3>Үнийн дүн нийт төлөх</h3>
                  <h2>
                    {{ reqObj.paymentStep.invoiceQpay.budget.toLocaleString() }}
                    ₮
                  </h2>
                </div>
                <div
                  v-for="bank in reqObj.paymentStep.invoiceQpay.json_data
                    .qPay_deeplink"
                  :key="`dd_${bank.name}`"
                >
                  <a
                    class="col-span-12 sm:col-span-6 lg:col-span-4"
                    target="_blank"
                    :href="bank.link"
                  >
                    <div
                      class="cursor-pointer relative mt-3 p-6 flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md shadow-md shadow-gray/20 transition-all hover:shadow-lg hover:shadow-gray/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    >
                      <div
                        class="relative mx-0 flex items-center gap-4 overflow-hidden bg-transparent bg-clip-border pt-0 text-gray-700 shadow-none"
                      >
                        <img
                          :src="bank.logo"
                          alt="Худалдаа хөгжлийн банк"
                          class="relative inline-block h-[40px] w-[40px] object-cover object-center wd-40 ht-40"
                        />
                        <div class="flex w-full flex-col gap-0.5">
                          <div class="flex items-center justify-between">
                            <h6
                              class="block font-sans text-l font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased"
                            >
                              {{ bank.name }}
                            </h6>
                          </div>
                          <p
                            class="block font-sans text-left text-xs font-light leading-relaxed text-blue-gray-900 antialiased"
                          >
                            картаар төлөх
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div slot="footer" class="dialog-footer wd-100p text-right">
              <el-button
                @click="checkInvoicePayed"
                type="primary"
                :style="
                  isMobile ? `position: fixed; bottom: 40px; right: 40px;` : ``
                "
                >Төлбөр шалгах</el-button
              >
              <!-- <el-button @click="getInvoice" type="warning"
                >Төлбөр төлөх</el-button
              > -->
            </div>
          </div>
          <div v-show="reqObj.step == 4">
            <div class="mg-t-10 text-center">
              <a
                :href="reqObj.pdfLink"
                target="_blank"
                class="btn btn-success mg-b-10"
                >Зөвшөөрөл татаж авах</a
              >
              <!--<div class="text-center">
                <h5>И-Баримт</h5>
              </div>
              <div class="containerImg">
                <img
                  width="200px"
                  :src="`data:image/png;base64, ${ebarimtObj.base64}`"
                />
              </div>-->

              <iframe
                :src="reqObj.pdfLink"
                type="application/pdf"
                width="100%"
                height="500px"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    v-model="pdfModal"
    fullscreen
    modal
    title="Монгол улсын нутаг дэвсгэрээр дамжин өнгөрөх олон улсын тээврийн хэрэгсэл"
    :append-to-body="true"
  >
    <div class="container">
      <img src="/st01.jpg" style="width: 100%; max-width: 1200px" />
    </div>
  </el-dialog>
</template>
<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

import { request } from "@/api/all";
import { mapActions } from "vuex";
import { setToken } from "@/libs/util";
import upldr from "@/views/Index/uploader.vue";
import moment from "moment";

export default {
  components: {
    upldr,
    //     Swiper,
    //   SwiperSlide
  },
  data() {
    return {
      achaaOptions: [
        {
          value: "Машин, техник",
          label: "Машин, техник",
        },
        {
          value: "Автокран",
          label: "Автокран",
        },
        {
          value: "Тоног төхөөрөмж",
          label: "Тоног төхөөрөмж",
        },
        {
          value: "Бусад",
          label: "Бусад",
        },
      ],
      locTypeIdSelected: [],
      locTypeId1Selected: [],
      isMobile: false,
      imgcur: "",
      imglst: [
        "images/slide1.jpg",
        "images/slide1.jpg",
      ],

      p1: null,
      p2: null,
      p3: null,
      isFocused1: false,
      isFocused2: false,
      options: [
        {
          value: "guide",
          label: "Guide",
        },
      ],
      letrs1: [
        "УБ",
        "УА",
        "УЕ",
        "УН",
        "УК",
        "АР",
        "БН",
        "БӨ",
        "БУ",
        "БР",
        "ГА",
        "ГО",
        "ГС",
        "ДА",
        "ДГ",
        "ДК",
        "ДН",
        "ДО",
        "ДУ",
        "ЗА",
        "НА",
        "ОР",
        "ӨВ",
        "ӨМ",
        "СБ",
        "СҮ",
        "СЭ",
        "ТӨ",
        "УВ",
        "ХО",
        "ХӨ",
        "ХЭ",
        "ЧО",
      ],
      letrs2: [
        "А",
        "Б",
        "В",
        "Г",
        "Д",
        "Е",
        "Ё",
        "Ж",
        "З",
        "И",
        "Й",
        "К",
        "Л",
        "М",
        "Н",
        "О",
        "Ө",
        "П",
        "Р",
        "С",
        "Т",
        "У",
        "Ү",
        "Ф",
        "Х",
        "Ц",
        "Ч",
        "Ш",
        "Щ",
        "Ъ",
        "Ы",
        "Ь",
        "Э",
        "Ю",
        "Я",
      ],

      pdfModal: false,
      taskModel: {
        task: "",
        defaultAttachmentId: null,
        //secondAttachmentId: null,
        //thirdAttachmentId: null,
        //fourthAttachmentId: null,
        //fifthAttachmentId: null,
        phone: null,
        mobile: null,
        d1: null,
      },
      bjMin: 0,
      bjMax: 0,
      vehicleModel: {
        task: "",
        taskEn: "",
        defaultAttachmentId: null,
        secondAttachmentId: null,
        thirdAttachmentId: null,
        fourthAttachmentId: null,
        fifthAttachmentId: null,
        phone: null,
        mobile: null,
        d1: null,
      },
      reqObj: {
        typeObj: {
          modal: false,
          selectedType: null,
          parentRequestObj: {
            id: null,
            task: null,
            defaultAttachmentId: null,
            secondAttachmentId: null,
            i3: null,
            st2: null,

            d1: null,
            d2: null,
            mobile: null,
            locTypeId: null,
            locTypeId1: null,
            fl1: null,
          },
        },
        vehicleFormShow: false,
        step: 1,
        //step: 2,
        requestTaskObj: {},
        vehicles: [],
        invoiceTaskObj: {},
        invoiceModel: {
          invoiceId: 3176483,
        },
        paymentStep: {
          invoiceQpay: {},
          paymentMethodSelected: null,
          paymentOptions: [
            {
              id: 1,
              name: "QPAY",
            },
            // {
            //   id: 2,
            //   name: "Social Pay",
            // },
          ],
        },
        modal: false,
        requestId: 3176479,
        vehicleBoxId: null,
        pdfLink: "",
        places: [],
        placesFlat: [],
        chirguulTypes: [],
        stepRequestRule: {
          task: [
            {
              required: true,
              message:
                "Тээвэрлэлт хийх Иргэн, аж ахуйн нэгж байгууллагын нэр оруулна уу",
              trigger: "blur",
            },
          ],
          i3: [
            {
              required: true,
              message: "И-Баримт авах хэлбэр сонгоно уу",
              trigger: "blur",
            },
          ],
          locTypeId: [
            {
              required: true,
              message: "Эхлэх цэг сононо уу",
              trigger: "blur",
            },
          ],
          locTypeId1: [
            {
              required: true,
              message: "Хүрэх цэг сононо уу",
              trigger: "blur",
            },
          ],
          defaultAttachmentId: [
            {
              required: true,
              message: "Албан хүсэлт оруулна уу",
              trigger: "blur",
            },
          ],
          // secondAttachmentId: [
          //   {
          //     required: true,
          //     message: "Тээврийн цагдаагийн газрын зөвлөмж оруулна уу",
          //     trigger: "blur",
          //   },
          // ],
          d1: [
            {
              required: true,
              message: "Тээвэрлэлт эхлэх огноо",
              trigger: "blur",
            },
          ],
          d2: [
            {
              required: true,
              message: "Тээвэрлэлт дуусах огноо",
              trigger: "blur",
            },
          ],
          fl1: [
            {
              required: true,
              message: "Нийт зайг оруулна уу /км/",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "Утасны дугаар оруулна уу",
              trigger: "blur",
            },
          ],
          mobile: [
            {
              required: true,
              message: "И-мэйл хаяг оруулна уу",
              trigger: "blur",
            },
          ],
          isTender: [
            {
              required: true,
              message: "Үйлчилгээний нөхцөл зөвшөөрж байна уу",
              trigger: "blur",
            },
          ],
        },
        stepVehicleRule: {
          task: [
            {
              required: true,
              message: "Тээврийн хэрэгслийн улсын дугаар оруулна уу",
              trigger: "blur",
            },
          ],
          position: [
            {
              required: true,
              message: "Жолоочын овог нэр оруулна уу",
              trigger: "blur",
            },
          ],
          i1: [
            {
              required: true,
              message: "Тэнхлэгийн тоо оруулна уу",
              trigger: "blur",
            },
          ],
          //locTypeId: [
          //  {
          //    required: true,
          //    message: "Чиргүүлийн төрөл сонгоно уу",
          //    trigger: "blur",
          //  },
          //],
          fl1: [
            {
              required: true,
              message: "Бодит жин оруулна уу",
              trigger: "blur",
            },
          ],
          langCode: [
            {
              required: true,
              message: "Жолоочын регистр оруулна уу",
              trigger: "blur",
            },
          ],
          defaultAttachmentId: [
            {
              required: true,
              message:
                "Тээврийн хэрэгсэл, чиргүүл гэрчилгээнийй хуулбар оруулна уу",
              trigger: "blur",
            },
          ],
          secondAttachmentId: [
            {
              required: true,
              message:
                "Тээврийн хэрэгсэл, чиргүүл гэрчилгээний хуулбар оруулна уу",
              trigger: "blur",
            },
          ],
          thirdAttachmentId: [
            {
              required: true,
              message: "Жолооны үнэмлэхний хуулбар оруулна уу",
              trigger: "blur",
            },
          ],
          fifthAttachmentId: [
            {
              required: true,
              message: "Тээврийн хэрэгслийн зураг оруулна уу",
              trigger: "blur",
            },
          ],
          st1: [
            {
              required: true,
              message: "Ачааны нэр төрөл бичнэ үү",
              trigger: "blur",
            },
          ],
        },
      },
      ebarimtObj: {
        modal: false,
        key: 0,
        id: 0,
        type: null,
        regNo: "",
        mode: 0,
        ebarimt: {},
        base64: null,
      },
    };
  },
  mounted() {
    this.imgcur = this.imglst[0];
    this.$refs.input1.focus();

    this.isMobile = window.innerWidth <= 768;
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    if (this.reqObj.step == 3) {
      this.paymentMethodSelected({ id: 1 });
    } else if (this.reqObj.step == 4) {
      this.createTransitRequestPdf();
    }
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    ...mapActions(["handleLoading"]),
    checkRoadpayPayment() {
      request({
        api: { method: "POST", url: "user/LoginApi" },
        params: {
          user: "roadpayuser",
          password: "roadpayuser#",
          key: "FN5PtdEgU0mKXK42q2KSog",
        },
        store: this.$store,
      }).then((resa) => {
        //console.log("-1", resa);
        this.handleLoading({ isLoading: false });
        if (resa !== false) {
          console.log("-2");
          setToken(resa.token);

          request({
            api: { method: "post", url: "RoadPay/checkRoadpayPayment" },
            data: {
              plateNo: this.vehicleModel.task,
            },
            store: this.$store,
          }).then((res) => {
            if (res != false) {
              if (res.data.payment > 0) {
                this.vehicleModel.isTender = true;
                this.vehicleModel.dec1 = res.data.payment;
              } else {
                this.vehicleModel.isTender = false;
              }

              //this.reqObj.pdfLink = res.data.link;
            }
          });
        }
      });
    },
    createTransitRequestPdf() {
      this.reqObj.step = 4;

      request({
        api: { method: "post", url: "EBarimt/CreateBill" },
        data: {
          id: this.reqObj.invoiceModel.invoiceId,
        },
        store: this.$store,
      }).then((resBarimt) => {
        if (resBarimt != false) {
          this.ebarimtObj.mode = 2;
          this.ebarimtObj.ebarimt = resBarimt.data.ebarimt;
          this.ebarimtObj.modal = true;
          this.ebarimtObj.key++;

          //

          request({
            api: { method: "post", url: "RoadPay/CreateTransitRequestPdf" },
            data: {
              id: this.reqObj.requestId,
            },
            store: this.$store,
          }).then((res) => {
            if (res != false) {
              this.reqObj.pdfLink = res.data.link;
              request({
                api: { method: "POST", url: "QR/Generate" },
                store: this.$store,
                data: { qr: resBarimt.data.ebarimt.qrData },
              }).then((resQR) => {
                if (resQR != false) {
                  this.ebarimtObj.base64 = resQR.data.base64;
                }
              });
            }
          });
        }
      });
    },
    axleCountChanged() {
      // if (res.data.info.type == "нам аралтай чиргүүл") {
      //             this.vehicleModel.locTypeId = 155783;
      //           } else {
      //             this.vehicleModel.locTypeId = 155784;
      //           }
      console.log(this.vehicleModel.i1, this.vehicleModel.isRepeat);

      var addJin = 1.5;
      if (this.vehicleModel.i1 === 3 || this.vehicleModel.i1 === 4) {
        addJin = 4.5;
      }
      this.bjMax = this.vehicleModel.i1 * 10 + addJin;

      if (
        this.vehicleModel.isRepeat === false ||
        this.vehicleModel.isRepeat === null ||
        this.vehicleModel.isRepeat === undefined
      ) {
        if (this.vehicleModel.i1 == 2) this.bjMin = 18;
        if (this.vehicleModel.i1 == 3) this.bjMin = 25;
        if (this.vehicleModel.i1 == 4) this.bjMin = 32;
        if (this.vehicleModel.i1 >= 5) this.bjMin = 38;
      } else if (this.vehicleModel.isRepeat === true) {
        if (this.vehicleModel.i1 === 3) this.bjMin = 28;
        if (this.vehicleModel.i1 === 4) this.bjMin = 36;
        if (this.vehicleModel.i1 === 5) this.bjMin = 40;
        if (this.vehicleModel.i1 >= 6) this.bjMin = 44;
      }

      if(this.vehicleModel.st1==="Автокран"){
        this.bjMin = 0;
        this.bjMax = 1000;
      }

      if(this.bjMax < this.vehicleModel.fl1){
        this.vehicleModel.fl1 = this.bjMax;
      }
      if(this.bjMin > this.vehicleModel.fl1){
        this.vehicleModel.fl1 = this.bjMin;
      }
    },
    cascaderOnChange(field, value) {
      console.log(field, value);
      if (value.length) {
        let _id =
          value[value.length - 1] != undefined ? value[value.length - 1] : null;
        this.taskModel[field] = _id;
      } else {
        this.taskModel[field] = null;
      }
    },
    checkInvoicePayed() {
      request({
        api: { method: "post", url: "qpay/checkInvoice" },
        data: {
          invoiceId: this.reqObj.invoiceModel.invoiceId,
        },
        store: this.$store,
      }).then((resQp) => {
        if (resQp != false) {
          if (resQp.data.paid) {
            this.createTransitRequestPdf();
            //this.reqObj
          } else {
            this.$message({
              type: "warning",
              message: "Төлбөр төлөгдөөгүй байна.",
            });
          }
          //this.reqObj.paymentStep.invoiceQpay = resQp.data;
        }
      });
    },
    paymentMethodSelected(item) {
      this.reqObj.paymentStep.paymentMethodSelected = item;
      if (item.id == 1) {
        request({
          api: { method: "GET", url: "qpay/GetSimpleInvoice" },
          params: {
            invoiceId: this.reqObj.invoiceModel.invoiceId,
          },
          store: this.$store,
        }).then((resQp) => {
          if (resQp != false) {
            this.reqObj.paymentStep.invoiceQpay = resQp.data;
          }
        });
      } else {
      }
    },

    getChirguulType() {
      if (
        this.vehicleModel.taskEn == "" ||
        this.vehicleModel.taskEn == undefined
      ) {
        this.vehicleModel.locTypeId = null;
      } else {
        request({
          api: { method: "GET", url: "Penalty/GetVehicleType" },
          params: {
            plateNo: this.vehicleModel.taskEn,
          },
          store: this.$store,
        }).then((res) => {
          if (res.data.info.type == "нам аралтай чиргүүл") {
            this.vehicleModel.locTypeId = 155783;
          } else {
            this.vehicleModel.locTypeId = 155784;
          }
          this.axleCountChanged();
          //this.reqObj.chirguulTypes.filter
          //this.reqObj.chirguulTypes = res.data.types;
        });
      }
    },
    showConfirm(e) {
      //console.log(e);
      this.$confirm(
        "<div style='text-align: justify'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Цахим системийн өгөгдлийг үнэн зөв мэдүүлээгүй, зөвшөөрөлгүй тээвэрлэлт гүйцэтгэсэн, тогтоосон маршрут, чиглэл зөрчсөн тээврийн хэрэгслийн зөвшөөрөгдөх даацын хэмжээ хэтрүүлсэн тохиолдолд Засгийн газрын 2023 оны 222 дугаар тогтоолын дагуу төлбөрийг гаргуулж,  холбогдох хууль тогтоомжийн дагуу хариуцлага тооцох бөгөөд учирсан хохирлыг нэхэмжлэхийг анхаарна уу. <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b style='color: red'>Үүнээс үүдэн гарах хариуцлагыг хүсэлт гаргасан иргэд, аж ахуйн нэгж, байгууллага хариуцна.</b></div>",
        "Үйлчилгээний нөхцөл",
        {
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          //type: 'warning',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          this.taskModel.isTender = true;
          // this.$message({
          //   type: 'success',
          //   message: 'Delete completed'
          // });
        })
        .catch(() => {
          this.taskModel.isTender = false;
        });
    },
    onResize() {
      this.isDesktop = window.innerWidth >= 991;
      this.isMobile = window.innerWidth <= 768;
    },
    handleNumber() {
      // if(!/^\d{4}$/.test(this.p1)){
      //     this.p1=null;
      // }
    },
    checkInputLength(event, inputRef) {
      if (inputRef == "input1") {
        if (event.target.value.length === 4) {
          this.$refs.input1.blur();
          this.isFocused1 = true;
        }
      }
    },
    doSrch() {
      let pltNo = this.p1 + this.p2 + this.p3;
      if (this.p2 == "ДК") {
        pltNo = this.p1 + this.p2;
      }
      if (
        this.p1 != null &&
        this.p2 != null &&
        this.p1.length == 4 &&
        this.p2.length == 2
      )
        this.$router.push({ name: "reg", params: { id: pltNo } });
      else alert("Улсын дугаар зөв оруулна уу");
    },

    fileUploaded(e, mode) {
      if (mode == 10) {
        this.taskModel.defaultAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 11) {
        this.taskModel.secondAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 20) {
        this.vehicleModel.defaultAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 21) {
        this.vehicleModel.secondAttachmentId = e.uploadedAttachmentId;
      } else if (mode == 22) {
        this.vehicleModel.thirdAttachmentId = e.uploadedAttachmentId;
      }
    },
    resetForm() {
      this.$refs.myform.resetFields();
    },
    resetVehicleForm() {
      this.$refs.vehicleform.resetFields();
    },
    getInvoice() {
      if (this.reqObj.vehicles.length == 0) {
        this.$message({
          type: "error",
          message: "Тээврийн хэрэгсэл бүртгэнэ үү.",
        });
        return false;
      }

      let msg = `
      
      ${
        this.reqObj.placesFlat.filter(
          (x) => x.id == this.reqObj.requestTaskObj.locTypeId
        )[0].label
      } - с ${
        this.reqObj.placesFlat.filter(
          (x) => x.id == this.reqObj.requestTaskObj.locTypeId1
        )[0].label
      }

      чиглэлд ${moment(this.reqObj.requestTaskObj.d1).format(
        "yyyy-MM-DD"
      )} - с ${moment(this.reqObj.requestTaskObj.d2).format(
        "yyyy-MM-DD"
      )} - ны хооронд ${this.reqObj.requestTaskObj.fl1} км зайг <br/>`;
      this.reqObj.vehicles.forEach((e) => {
        msg += "<hr/>";
        msg += `${e.task} дугаартай ${e.i1} тэнхлэгтэй ${e.fl1} тонн ачаатай `;
        if (e.isRepeat) {
          msg += `${e.taskEn} чиргүүлтэй <br/>`;
        } else {
          msg += `чиргүүлгүй <br/>`;
        }
        msg += "<hr/>";
      });
      msg +=
        this.reqObj.vehicles.length > 1
          ? " тээврийн хэрэгслүүдээр"
          : " тээврийн хэрэгслээр ";
      msg += " ачаа тээвэрлэх хүсэлт илгээх үү?";
      this.$confirm(msg, "Санамж", {
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        type: "info",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          request({
            api: {
              method: "post",
              url: "RoadPay/CreateInvoiceByTransitRequest",
            },
            data: {
              id: this.reqObj.requestId,
            },
            store: this.$store,
          }).then((res) => {
            if (res != false) {
              //console.log(res.data);
              this.reqObj.step = 3;
              this.reqObj.invoiceModel = res.data;

              //GetSimpleInvoice
              request({
                api: { method: "GET", url: "qpay/GetSimpleInvoice" },
                params: {
                  invoiceId: this.reqObj.invoiceModel.invoiceId,
                },
                store: this.$store,
              }).then((resQp) => {
                this.reqObj.paymentStep.invoiceQpay = resQp;
                this.paymentMethodSelected({ id: 1 });
                //this.reqObj.chirguulTypes = res.data.types;
              });
            }

            //this.reqObj.chirguulTypes = res.data.types
          });

          // this.$message({
          //   type: 'success',
          //   message: 'Delete completed'
          // });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    fetchVehicleFormData() {
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "Type/GetData" },
        params: {
          key: "ezam_tranzit_chirguultype",
        },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });
        this.reqObj.chirguulTypes = res.data.types;
      });
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "GET", url: "Task/GetInherited" },
        params: {
          id: 0,
          objectTypeId: 603,
          parentId: 0,
          projectId: 56542,
          boxId: this.reqObj.vehicleBoxId,
        },
        store: this.$store,
      }).then((res) => {
        this.handleLoading({ isLoading: false });

        this.vehicleModel = res.data.task;
      });
    },
    onVehicleSubmit() {
      // console.log('dd');
      if (this.vehicleModel.isTender) {
        this.$message({
          type: "error",
          message:
            this.vehicleModel.task +
            " дугаартай тээврийн хэрэгсэл зам ашигласан төлбөрийн үлдэгдэлтэй байна. Төлбөрөө төлнө үү.",
        });
        return false;
      }
      if (
        this.vehicleModel.isRepeat &&
        (this.vehicleModel.taskEn == null || this.vehicleModel.taskEn == "")
      ) {
        this.$message({
          type: "error",
          message:
            "Чиргүүлтэй эсэх тийм үед чиргүүлийн улсын дугаар заавал оруулна уу.",
        });
        return false;
      }
      if (
        this.vehicleModel.fl1 == null ||
        this.vehicleModel.fl1 == undefined ||
        this.vehicleModel.fl1 == 0
      ) {
        this.$message({
          type: "error",
          message: "Бодит жин оруулна уу.",
        });
        return false;
      }

    if(this.vehicleModel.i1 < 2){
      this.$message({
          type: "error",
          message: "Тэнхлэгийн тоо зөв оруулна уу.",
        });
        return false;
    }

      if (
        //(this.vehicleModel.taskEn != null || this.vehicleModel.taskEn != "") &&
        this.vehicleModel.isRepeat &&
        this.vehicleModel.i1 == 2
      ) {
        this.$message({
          type: "error",
          message: "Чиргүүлтэй үед тэнхлэгийн тоо багадаа 3 байна.",
        });
        return false;
      }

      this.$refs.vehicleform.validate((valid) => {
        if (valid) {
          this.handleLoading({ isLoading: true });
          this.vehicleModel.d1 = new Date();
          this.vehicleModel.organizationTaskId = this.reqObj.requestId;
          request({
            api: { method: "post", url: "Task/PostInherited" },
            data: { model: this.vehicleModel },
            store: this.$store,
          }).then((resvp) => {
            this.handleLoading({ isLoading: false });
            if (resvp != false) {
              this.resetVehicleForm();
              //this.reqObj.modal = false;
              //this.reqObj.requestId = res.data.taskId;
              this.handleLoading({ isLoading: true });
              request({
                api: { method: "get", url: "Task/taskInheritedDetail" },
                params: { id: resvp.taskId },
                store: this.$store,
              }).then((resvh) => {
                this.handleLoading({ isLoading: false });
                this.reqObj.vehicles.push(resvh.data.task);
                this.fetchVehicleFormData();
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    onSubmit() {
      // console.log('dd');
      if (this.taskModel.locTypeId == this.taskModel.locTypeId1) {
        this.$message.error(
          "Эхлэх болон хүрэх байршил нэг цэгт байх боломжгүй."
        );
        return false;
      }

      if (
        this.taskModel.i3 == 1 &&
        (this.taskModel.st2 == null || this.taskModel.st2 == "")
      ) {
        this.$message.error(
          "И-Баримт авах хэлбэр ААН сонгосон үед ААН регистр заавал оруулна уу."
        );
        return false;
      }

      if (this.taskModel.isTender == false) {
        this.$message.error("Үйлчилгээний нөхцөл зөвшөөрнө үү.");
        return false;
      }
      this.$refs.myform.validate((valid) => {
        if (valid) {
          this.handleLoading({ isLoading: true });
          //this.taskModel.d1 = new Date();
          request({
            api: { method: "POST", url: "Task/PostInherited" },
            data: { model: this.taskModel },
            store: this.$store,
          }).then((res) => {
            this.handleLoading({ isLoading: false });
            if (res != false) {
              //this.resetForm();
              //this.reqObj.modal = false;
              this.reqObj.requestId = res.taskId;
              this.handleLoading({ isLoading: true });
              request({
                api: { method: "get", url: "Task/taskInheritedDetail" },
                params: { id: this.reqObj.requestId },
                store: this.$store,
              }).then((resTd) => {
                this.handleLoading({ isLoading: false });
                this.reqObj.requestTaskObj = resTd.data.task;
                this.reqObj.vehicleBoxId = resTd.data.task.b.filter(
                  (x) => x.ot == 603
                )[0].id;

                this.fetchVehicleFormData();

                this.reqObj.step = 2;
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    showPdfModal() {
      this.pdfModal = true;
    },
    checkRequestId() {
      let self = this;
      if (this.reqObj.typeObj.selectedType == 0) {
        self.reqObj.typeObj.modal = false;
        this.showReqModal();
      } else {
        this.handleLoading({ isLoading: true });
        request({
          api: { method: "POST", url: "user/LoginApi" },
          params: {
            user: "roadpayuser",
            password: "roadpayuser#",
            key: "FN5PtdEgU0mKXK42q2KSog",
          },
          store: this.$store,
        }).then((resa) => {
          this.handleLoading({ isLoading: false });
          if (resa !== false) {
            if (
              self.reqObj.typeObj.requestId != undefined &&
              self.reqObj.typeObj.requestId != null
            ) {
              setToken(resa.token);
              this.handleLoading({ isLoading: true });
              request({
                api: { method: "post", url: "roadpay/checkRequestId" },
                data: {
                  id: self.reqObj.typeObj.requestId,
                },
                store: this.$store,
              }).then((res) => {
                this.handleLoading({ isLoading: false });
                if (res != false) {
                  self.reqObj.typeObj.modal = false;
                  self.reqObj.typeObj.parentRequestObj = res.data.task;
                  this.showReqModal();
                }
              });
            } else {
              alert("Зөвшөөрлийн дугаар оруулна уу.");
            }
          }
        });
      }
    },
    cascaderModelRecursive(data, cid, objName) {
      this[objName].push(cid);
      if (data.filter((d) => d.id == cid).length) {
        let pid = data.filter((d) => d.id == cid)[0].parentId;
        if (pid > 0) {
          this.cascaderModelRecursive(data, pid, objName);
        }
      }
    },
    showReqModal() {
      this.handleLoading({ isLoading: true });
      request({
        api: { method: "POST", url: "user/LoginApi" },
        params: {
          user: "roadpayuser",
          password: "roadpayuser#",
          key: "FN5PtdEgU0mKXK42q2KSog",
        },
        store: this.$store,
      }).then((resa) => {
        this.handleLoading({ isLoading: false });
        if (resa !== false) {
          //console.log("-2");
          setToken(resa.token);
          this.handleLoading({ isLoading: true });
          request({
            api: { method: "GET", url: "Type/GetAsyncTreeDataNA" },
            params: {
              key: "ezam_tranzit_places",
            },
            store: this.$store,
          }).then((res) => {
            this.handleLoading({ isLoading: false });
            this.reqObj.places = res.data.types;
          });
          this.handleLoading({ isLoading: true });
          request({
            api: { method: "GET", url: "Type/GetTreeData" },
            params: {
              key: "ezam_tranzit_places",
            },
            store: this.$store,
          }).then((res) => {
            this.handleLoading({ isLoading: false });
            this.reqObj.placesFlat = res.data.types;
            this.handleLoading({ isLoading: true });
            request({
              api: { method: "GET", url: "Task/GetInherited" },
              params: {
                id: 0,
                objectTypeId: 596,
                parentId: 0,
                projectId: 56541,
                boxId: 0,
              },
              store: this.$store,
            }).then((res) => {
              this.handleLoading({ isLoading: false });
              // console.log('res', res);
              if (res !== false) {
                // alert(res);
                //console.log('res', res.data.task);
                res.data.task.i3 = null;
                this.taskModel = res.data.task;

                if (this.reqObj.typeObj.selectedType == 1) {
                  this.taskModel.secondOrganizationTaskId =
                    this.reqObj.typeObj.parentRequestObj.id;
                  this.taskModel.isRepeat = true;
                  this.taskModel.task =
                    this.reqObj.typeObj.parentRequestObj.task;
                  this.taskModel.d1 = this.reqObj.typeObj.parentRequestObj.d1;
                  this.taskModel.d2 = this.reqObj.typeObj.parentRequestObj.d2;

                  this.taskModel.locTypeId =
                    this.reqObj.typeObj.parentRequestObj.locTypeId;
                  this.taskModel.locTypeId1 =
                    this.reqObj.typeObj.parentRequestObj.locTypeId1;
                  try {
                    this.cascaderModelRecursive(
                      this.reqObj.placesFlat,
                      this.taskModel.locTypeId,
                      "locTypeIdSelected"
                    );
                    this.locTypeIdSelected = this.locTypeIdSelected.reverse();

                    this.cascaderModelRecursive(
                      this.reqObj.placesFlat,
                      this.taskModel.locTypeId1,
                      "locTypeId1Selected"
                    );
                    this.locTypeId1Selected = this.locTypeId1Selected.reverse();
                  } catch {}

                  this.taskModel.defaultAttachmentId =
                    this.reqObj.typeObj.parentRequestObj.defaultAttachmentId;
                  this.taskModel.secondAttachmentId =
                    this.reqObj.typeObj.parentRequestObj.secondAttachmentId;
                  this.taskModel.i3 = this.reqObj.typeObj.parentRequestObj.i3;
                  this.taskModel.st2 = this.reqObj.typeObj.parentRequestObj.st2;

                  this.taskModel.mobile =
                    this.reqObj.typeObj.parentRequestObj.mobile;
                  this.taskModel.fl1 = this.reqObj.typeObj.parentRequestObj.fl1;
                }

                this.reqObj.modal = true;
              }
            });
          });
        }
      });
    },
  },
};
</script>
<style>
@import "https://res.gosmart.mn/static/df/assets/css/dashforge.css";
.el-dialog__header {
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  font-weight: 500;
}
.containerImg {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Adjust as needed */
  padding-bottom: 20px;
  /* Adjust as needed */
}
.el-alert__description {
  font-size: 12px !important;
  line-height: 16px !important;
}
.el-alert__title {
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 700;
}
</style>
